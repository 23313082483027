/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-20",
    versionChannel: "nightly",
    buildDate: "2024-07-20T00:05:09.529Z",
    commitHash: "1c8e95a061a40ccd4b2fdca30a66eb7cc2fbe12d",
};
